
<template>
    <object :data="Constants.ServerUrl + 'ComenziFurnizori/print_pdf/' + IdComandaFurnizor" type="application/pdf" width="100%" :height="parentHeight">
        <p><a :href="Constants.ServerUrl + 'ComenziFurnizori/print_pdf/' + IdComandaFurnizor"></a></p> 
    </object>                        
</template>

<script>
import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage'
import Constants from '@/backend/Constants'
export default {
    name: "oferta_pdf",
    extends: BasePage,

    data(){
        return {
            Constants: Constants,
            IdComandaFurnizor: 0,   
            parentHeight: 1000,        
        }
    },
    methods: {
        genereaza() {  
            this.IdComandaFurnizor = this.$route.params.comanda
        }
    },
    mounted() {
        this.genereaza()
    }
}
</script>

